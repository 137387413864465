<template>
    <GChart class="w-3/4 mx-auto" :class="classes" :settings="{ packages: ['corechart'], }" type="PieChart"
        :createChart="createChart" :data="data" :options="options" :resizeDebounce="500" @ready="onChartReady" />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';
export default {
    name: 'DonutChart',
    components: { GChart },
    props: ['data', 'name', 'height', 'width', 'classes'],

    data() {
        return {
            chart: null,
            gvar: null, // this is placeholder for google variable
            options: {
                height: this.height || '100%',
                width: this.width || '100%',
                colors: [
                    '#ff7675', '#0984e3', '#00b894', 
                    '#fdcb6e', '#e17055', '#6c5ce7', 
                    '#d63031', '#e84393', '#a29bfe', 
                    '#74b9ff', '#00cec9', '#55efc4', 
                    '#ffeaa7', '#fab1a0', '#636e72', 
                    '#b2bec3', '#2d3436'],
                pieHole: 0.4,
                animation: {
                    startup: true,
                    easing: 'linear',
                    duration: 1000,
                },
                legend: {
                    position: 'bottom',
                    textStyle: {
                        color: 'black',
                        fontSize: 16,
                    },
                },
                chartArea: {
                    left: 50,
                    top: 50,
                    // width: '100%',
                    // height: '100%',
                },
                backgroundColor: 'transparent',
                titleTextStyle: {
                    color: 'black',
                    fontSize: 16,
                },
                
            }
        }
    },

    computed: {
        google() {
            return window.google
        },
        el() {
            return document.getElementById(this.name)
        },
        chartType() {
            return 'PieChart'
        }
    },

    beforeDestroy() {
        if (this.chart) {
            this.chart.clearChart()
        }
    },

    methods: {
        createChart(el, google) {
            if (el === undefined) {
                return
            }
            return new google.visualization.PieChart(el)
        },
        onChartReady(chart, google) {
            this.gvar = google
            this.chart = chart
        }
    }

}
</script>

<style></style>