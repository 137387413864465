<template>
    <v-container class="w-full mx-auto">
        <v-row class="mx-auto w-full">
            <div class="w-full mx-auto mb-12 xl:mb-0 mt-14">
                <div class="relative flex flex-col mx-auto bg-white w-full mb-6 shadow-lg rounded-3xl ">
                    <v-skeleton-loader :loading="loading" type="list-item-three-line" class="mb-4">
                    <div class="rounded-t mb-0 px-4 py-3 border-0">
                        <div class="flex justify-between items-center">
                            <v-icon>mdi-storefront-check-outline</v-icon>
                            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3 class="font-semibold text-base text-black">Top 5 Merchants</h3>
                            </div>
                        </div>
                    </div>

                    <div class="block w-full overflow-x-auto">
                        <table class="items-center bg-transparent w-full border-collapse ">
                            <thead>
                                <tr>
                                    <th
                                        class="px-6 text-black align-middle border border-solid border-gray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Name
                                    </th>
                                    <th
                                        class="px-6 text-black align-middle border border-solid border-gray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Percentage
                                    </th>
                                    <th
                                        class="px-6 text-black align-middle border border-solid border-gray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        Amount
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(expense, i) in merchantExpenses" :key="i">
                                    <th
                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex">
                                        <v-avatar class="mx-2" :color="colors[i]" :size=36></v-avatar>
                                        <span class="my-auto">{{ expense.merchant }}</span>
                                    </th>
                                    <td
                                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <v-progress-linear rounded :value="expense.percent" :color="colors[i]" height="20">
                                            <template v-slot:default="{ value }">
                                                <strong>{{ Math.ceil(value) }}%</strong>
                                            </template>
                                        </v-progress-linear>
                                    </td>
                                    <td
                                        class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4 font-bold">
                                        {{ expense.amount }}
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </v-skeleton-loader>
                </div>
            </div>
        </v-row>
    </v-container>
</template>

<script>
// import axios from "axios";

export default {
    props: ["banks", "merchantExpenses", "loading"],
    data() {
        return {
            itemId: null,
            userId: null,
            colors: ["red", "primary", "teal", "amber", "pink"],
        };
    },

    methods: {

    },

}
</script>

<style>

</style>