<template>
    <v-card rounded="xl">
        <v-row class="">
            <!-- total in and total out with icons and text -->
            <v-col cols="6">
                <v-card-text class="flex flex-col justify-center items-center">
                    <v-skeleton-loader :loading="loading" type="card" height="50" width="100%" class="mb-4">
                    <div class="flex">
                        <span class="text-lg font-bold">Total In</span>
                        <v-icon size="20" class="text-xl px-4" color="green">mdi-arrow-down-bold-circle-outline</v-icon>
                    </div>
                    <v-card-subtitle class="">£{{ totalCredit }}</v-card-subtitle>
                </v-skeleton-loader>
                </v-card-text>
            </v-col>
            <v-col cols="6">
                <v-card-text class="flex flex-col justify-center items-center">
                    <v-skeleton-loader :loading="loading" type="card" height="50" width="100%" class="mb-4">
                    <div class="flex">
                        <span class="text-lg font-bold">Total Out</span>
                        <v-icon size="20" class="text-xl px-4" color="red">mdi-arrow-up-bold-circle-outline</v-icon>
                    </div>
                    <v-card-subtitle class="">£{{ totalDebit }}</v-card-subtitle>
                </v-skeleton-loader>
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {

    props: {
        totalCredit: {
            type: Number,
            required: true
        },
        totalDebit: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
        }
    },

    mounted() {
    },

    computed: {
    },

    methods: {
        
    }
}
</script>

<style>

</style>