<template>
  <v-container class="w-full h-full">

    <div class="flex flex-col col-span-full bg-white shadow-lg rounded-3xl border border-slate-200">
      <div class="px-5 py-6">
        <div class="md:flex md:justify-between md:items-center">
          <div class="flex items-center mb-4 md:mb-0">
            <div class="mr-4">
              <img class="inline-flex rounded-full" src="@/assets/util/bird.svg" width="64" height="64" alt="User" />
            </div>
            <div>
              <div class="mb-2">Hey <strong class="font-medium text-gray-800">{{ firstname }}</strong> 👋, welcome back.
                This is your current total balance
                at {{ currentBank != null ? currentBank.bank : '' }}:</div>
              <div>
                <div class="text-3xl font-bold text-black">£{{ balance }}</div>
              </div>
            </div>
          </div>
          <v-col cols="12" sm="1" class="shrink-0 justify-start md:justify-end">
            <!-- <filter-component :banks="banks" :accounts="accounts" :calendarDate="lineChartDateFormatted"
              :requiredFilters="requiredFilters" @bankChangedCallback="bankChangedCallback"
              @accountChangedCallback="accountChangedCallback"
              @transactionDateChangedCallback="transactionDateChangedCallback" @searchCallback="searchCallback">
            </filter-component> -->
            <filter-component :banks="banks" :accounts="accounts" :calendarDate="lineChartDateFormatted"
              :requiredFilters="requiredFilters">
            </filter-component>
          </v-col>
        </div>
      </div>
    </div>


    <v-row class="mt-4">
      <v-col cols="12" md="9">
        <v-card rounded="xl" width="100%" height="473">
          <v-container class="h-full w-full">
            <v-card-actions class="h-1/4">
              <v-card-title class="text-xl font-bold">Your Spendings in <span class="text-red-500 px-2">{{ monthName
              }}</span> against : {{ lineChartCompareDateFormatted
}}</v-card-title>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-card-text class="h-4/5 w-full">
              <v-skeleton-loader type="card" :loading="loading" height="100%">
                <expense-chart :lineChartData="lineChartData" :monthName="monthName"></expense-chart>
              </v-skeleton-loader>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <total-in-out :totalCredit="totalCredit" :totalDebit="totalDebit" :loading="loading"></total-in-out>
        <v-card rounded="xl" class="flex justify-center mx-auto align-middle mt-5 md:my-2 h-80">
          <div class="flex px-4">
            <v-card-title>Expenses by Category</v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="showChartPopup = true" class="cursor-pointer">mdi-magnify-plus-outline</v-icon>
            <v-dialog max-width="700px" color="red lighten-1" v-model="showChartPopup">
              <v-card rounded="xl" class="my-0 h-full w-full mx-auto">
                <div class="flex px-4 mx-auto">
                  <v-card-title>Expenses by Category</v-card-title>
                  <v-spacer></v-spacer>
                  <v-icon @click="showChartPopup = false" class="cursor-pointer">mdi-window-close</v-icon>
                </div>
                <div class="flex justify-center mx-auto" id="popupChart">
                  <v-skeleton-loader :loading="loading" type="card" height="100%" width="100%" class="mb-4">
                    <donut-chart :data="donutChartData" :name="'popupChart'" :height="450" :width="450"></donut-chart>
                  </v-skeleton-loader>
                </div>
                <!-- :classes="'overflow-x-scroll overflow-y-scroll md:overflow-x-hidden md:overflow-x-hidden'" -->
              </v-card>
            </v-dialog>
          </div>
          <div class="flex justify-center w-2/3 px-4">
            <v-skeleton-loader :loading="loading" type="card" height="100%" width="100%" class="mb-4">
              <donut-chart id="donutChart" :data="donutChartData" :name="'donutChart'" :height="'100%'"
                :width="'100%'"></donut-chart>
            </v-skeleton-loader>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col cols="12" class="w-full p-0">
        <merchant-transactions :banks="banks" :merchantExpenses="merchantExpenses" :loading="loading"
          class="w-full"></merchant-transactions>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import axios from 'axios'
import DonutChart from '../components/Dashboard/DonutChart.vue'
import moment from 'moment';
import MerchantTransactions from '../components/Dashboard/MerchantTransactions.vue';
import { Messages } from '@/utils/constants';
import ExpenseChart from '../components/Dashboard/ExpenseChart.vue';
import TotalInOut from '../components/Dashboard/TotalInOut.vue';
import FilterComponent from '../components/Partials/FilterComponent.vue';

export default {
  name: 'Home',
  components: {
    DonutChart,
    MerchantTransactions,
    ExpenseChart,
    TotalInOut,
    FilterComponent
  },

  //TODO: remove requests for locations analytics

  data() {
    return {
      hasLocationData: false,
      apiKey: '',
      showChartPopup: false,
      lineChartDate: moment().startOf('month').format("YYYY-MM-DD"),
      lineChartCompareDate: moment().startOf('month').subtract(1, 'month').format("YYYY-MM-DD"),
      // moment(this.lineChartCompareDate).subtract(1, 'month').format("YYYY-MM-DD")
      // monthName: moment().startOf('month').format("MMMM"),
      balance: '795',
      locations: [],
      // categoryAnalytics: [],
      categoryAnalytics: [
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
          "category": "Rent",
          "amount": 1200.00,
          "budget": 1200.00,
          "count": 12,
          "transactionType": "DEBIT",
          "date": moment().startOf('month').format("YYYY-MM-DD")
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
          "category": "Entertainment",
          "amount": 240.00,
          "budget": 240.00,
          "count": 6,
          "transactionType": "DEBIT",
          "date": moment().startOf('month').format("YYYY-MM-DD")
        },
      ],
      chartData: [
        ['Year', 'Sales', 'Expenses', 'Profit'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350]
      ],
      // donutChartData: [],
      donutChartData: [],
      donutChartDataOptions: {
        title: 'Expenses by Category',
        is3D: true,
        pieHole: 0.4,
      },
      lineChartData: [],
      // lineChartResponse: [],
      lineChartResponse: [
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 1,
          "amount": 1200.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 2,
          "amount": 20.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 3,
          "amount": 200.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 6,
          "amount": 20.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 10,
          "amount": 5.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 16,
          "amount": 58.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 23,
          "amount": 28.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 27,
          "amount": 8.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "day": 28,
          "amount": 78.00,
          "count": 1,
          "transactionType": "DEBIT"
        },
      ],
      itemId: null,
      accountId: null,
      // banks: [],
      banks: [
        {
          "userId": 13,
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "bank": "Santander (UK) - Personal and Business",
          "status": "ACTIVE",
          "balance": null,
          "accounts": [
            {
              "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
              "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
              "balance": 100.0,
              "mask": "0000",
              "name": "Plaid Checking",
              "officialName": "Plaid Gold Standard 0% Interest Checking",
              "type": "DEPOSITORY",
              "subtype": "CHECKING"
            }
          ]
        },
        {
          "userId": 13,
          "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
          "bank": "Chase",
          "status": "ACTIVE",
          "balance": null,
          "accounts": [
            {
              "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
              "accountId": "rb8k6gMgVDsZQq8vJPAqtrpydQNkR9F7zwGm9",
              "balance": 100.0,
              "mask": "0000",
              "name": "Plaid Checking",
              "officialName": "Plaid Gold Standard 0% Interest Checking",
              "type": "DEPOSITORY",
              "subtype": "CHECKING"
            },
            {
              "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
              "accountId": "z3yp6aWaqruy8oB3lJmoFQLz693wGdSlnaejN",
              "balance": 200.0,
              "mask": "1111",
              "name": "Plaid Saving",
              "officialName": "Plaid Silver Standard 0.1% Interest Saving",
              "type": "DEPOSITORY",
              "subtype": "SAVINGS"
            }
          ]
        }
      ],
      // accounts: [],
      accounts: [
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
          "balance": 100.0,
          "mask": "0000",
          "name": "Plaid Checking",
          "officialName": "Plaid Gold Standard 0% Interest Checking",
          "type": "DEPOSITORY",
          "subtype": "CHECKING"
        },
        {
          "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
          "accountId": "rb8k6gMgVDsZQq8vJPAqtrpydQNkR9F7zwGm9",
          "balance": 100.0,
          "mask": "0000",
          "name": "Plaid Checking",
          "officialName": "Plaid Gold Standard 0% Interest Checking",
          "type": "DEPOSITORY",
          "subtype": "CHECKING"
        },
        {
          "itemId": "6KrjkeGeW6uAeyMN19ZyFV3NlwGlozugAzKv8",
          "accountId": "z3yp6aWaqruy8oB3lJmoFQLz693wGdSlnaejN",
          "balance": 200.0,
          "mask": "1111",
          "name": "Plaid Saving",
          "officialName": "Plaid Silver Standard 0.1% Interest Saving",
          "type": "DEPOSITORY",
          "subtype": "SAVINGS"
        }
      ],
      // merchantExpenses: [],
      merchantExpenses: [
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
          "merchant": "Your Landlord xx",
          "amount": 1200.00,
          "count": 12,
          "percent": 50.00,
          "transactionType": "DEBIT",
          "date": moment().startOf('month').format("YYYY-MM-DD")
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
          "merchant": "Spotify",
          "amount": 120.00,
          "count": 12,
          "percent": 20.00,
          "transactionType": "DEBIT",
          "date": moment().startOf('month').format("YYYY-MM-DD")
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
          "merchant": "Netflix",
          "amount": 120.00,
          "count": 12,
          "percent": 20.00,
          "transactionType": "DEBIT",
          "date": moment().startOf('month').format("YYYY-MM-DD")
        },
        {
          "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
          "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
          "merchant": "EE",
          "amount": 60.00,
          "count": 6,
          "percent": 10.00,
          "transactionType": "DEBIT",
          "date": moment().startOf('month').format("YYYY-MM-DD")
        },
      ],
      currentBank: {
        "userId": 13,
        "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
        "bank": "Santander (UK) - Personal and Business",
        "status": "ACTIVE",
        "balance": null,
        "accounts": [
          {
            "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
            "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
            "balance": 100.0,
            "mask": "0000",
            "name": "Plaid Checking",
            "officialName": "Plaid Gold Standard 0% Interest Checking",
            "type": "DEPOSITORY",
            "subtype": "CHECKING"
          }
        ]
      },
      currentAccount: {
        "itemId": "VeZbeVbRbpFBa3vpnjyrCNlEJXmRqEfWon793",
        "accountId": "BnL4n14e4wSRZ9K64qLgsW7kqrgPvxHAw9bMW",
        "balance": 100.0,
        "mask": "0000",
        "name": "Plaid Checking",
        "officialName": "Plaid Gold Standard 0% Interest Checking",
        "type": "DEPOSITORY",
        "subtype": "CHECKING"
      },
      totalCredit: 1200,
      totalDebit: 405,
      requiredFilters: {
        banks: true,
        accounts: true,
        transactionDate: true,
        rangePicker: false,
        maxDate: this.lineChartDate,
        searchButton: true
      },
      // loading: true
      loading: false
    }
  },

  // created() {
  //   this.apiKey = process.env.VUE_APP_MAP_API_KEY
  // },

  mounted() {
    // this.loadAllData()
    this.mapCategoryAnalytics()
    this.mapLineChartData([])
  },

  computed: {
    userId() {
      // return this.$session.get('user').id
      return 11
    },
    firstname() {
      return 'John'
      // return this.$session.get('user').firstname
    },
    monthName() {
      return moment(this.lineChartDate).format("MMMM")
    },
    lineChartDateFormatted() {
      return moment(this.lineChartDate).format("YYYY-MM")
    },
    lineChartCompareDateFormatted() {
      return moment(this.lineChartCompareDate).format("YYYY-MM")
    }
  },

  methods: {
    async loadAllData() {
      await this.getBanks()
      await this.loadBalance()
      await this.loadCategoryAnalytics()
      await this.loadLineGraphData()
      await this.getTotalCredit()
      await this.getTotalDebit()
      this.loading = false
    },
    async loadAllDataExceptBank() {
      this.loadMerchantExpenses()
      this.loadCategoryAnalytics()
      this.loadLineGraphData()
      this.loadBalance()
      this.getTotalCredit()
      this.getTotalDebit()
    },
    async loadBalance() {
      let payload = {
        user_id: this.userId,
        item_id: this.itemId
      }
      // console.log('requesting balance');
      // const response = await axios.post(process.env.VUE_APP_API_URL + '/balance/all', payload, { headers: { "Content-Type": "application/json" } })
      const response = await axios.post(process.env.VUE_APP_API_URL + '/balance', payload, { headers: { "Content-Type": "application/json" } })
      this.balance = response.data
    },
    async loadCategoryAnalytics() {
      // console.log('requesting categoryAnalytics for date');
      let payload = {
        user_id: this.userId,
        item_id: this.itemId,
        account_id: this.accountId,
        transaction_type: 'DEBIT',
        date: this.lineChartDate
      }
      const response = await axios.post(process.env.VUE_APP_API_URL + '/analysis/category', payload, { headers: { "Content-Type": "application/json" } })
      this.categoryAnalytics = response.data
      this.mapCategoryAnalytics()

    },
    async loadLineGraphData() {
      let payload = {
        user_id: this.userId,
        item_id: this.itemId,
        account_id: this.accountId,
        transaction_type: 'DEBIT',
        date: this.lineChartDate
        // date: '2023-01-27'
      }

      let payload2 = {
        user_id: this.userId,
        item_id: this.itemId,
        account_id: this.accountId,
        transaction_type: 'DEBIT',
        date: this.lineChartCompareDate
        // date: '2022-12-27'
      }

      // console.log('requesting data for date');

      const response = await axios.post(process.env.VUE_APP_API_URL + '/analysis/date', payload, { headers: { "Content-Type": "application/json" } })
      const response2 = await axios.post(process.env.VUE_APP_API_URL + '/analysis/date', payload2, { headers: { "Content-Type": "application/json" } })
      this.lineChartResponse = response.data
      // console.log(response2.data)
      this.mapLineChartData(response2.data)
    },
    async getTotalCredit() {
      // console.log('requesting total credit');
      let payload = {
        user_id: this.userId,
        item_id: this.itemId,
        account_id: this.accountId,
        transaction_type: 'CREDIT',
        // date: moment(this.lineChartDate).subtract(1, 'month').format("YYYY-MM-DD") // for testing
        date: moment(this.lineChartDate).format("YYYY-MM-DD")
      }


      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/analysis/date-type', payload, { headers: { "Content-Type": "application/json" } })
        this.totalCredit = response.data
      } catch (error) {
        this.$store.dispatch('showSnackBar', { status: true, message: error.response.data.errors[0], type: Messages.ERROR })
      }
    },
    async getTotalDebit() {
      // console.log('requesting total debit');
      let payload = {
        user_id: this.userId,
        item_id: this.itemId,
        account_id: this.accountId,
        transaction_type: 'DEBIT',
        // date: moment(this.lineChartDate).subtract(1, 'month').format("YYYY-MM-DD") // for testing
        date: moment(this.lineChartDate).format("YYYY-MM-DD")
      }


      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/analysis/date-type', payload, { headers: { "Content-Type": "application/json" } })
        this.totalDebit = response.data
      } catch (error) {
        this.$store.dispatch('showSnackBar', { status: true, message: error.response.data.errors[0], type: Messages.ERROR })
      }
    },
    async getBanks() {
      // console.log('requesting banks');
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/items', { user_id: this.userId }, { headers: { "Content-Type": "application/json" } })
        this.banks = response.data.banks

        if (this.banks.length > 0) {
          this.currentBank = this.banks[0]
          this.itemId = this.banks[0].itemId

          this.accounts = this.currentBank.accounts
          this.currentAccount = this.accounts[0]
          this.accountId = this.currentAccount.accountId
        }
      } catch (error) {
        // concat error message
        let errorMessage = ''
        error.response.data.errors.forEach(element => {
          errorMessage += element + ', '
        });
        this.$store.dispatch('showSnackBar', { status: true, message: errorMessage, type: Messages.ERROR })
      }
    },
    async loadMerchantExpenses() {
      // console.log('requesting merchantExpenses');
      let payload = {
        user_id: this.userId,
        item_id: this.itemId,
        account_id: this.accountId,
        transaction_type: 'DEBIT',
        date: this.lineChartDate
      }
      const response = await axios.post(process.env.VUE_APP_API_URL + '/analysis/merchant', payload, { headers: { "Content-Type": "application/json" } })
      this.merchantExpenses = response.data
    },
    mapCategoryAnalytics() {
      let array = []
      array.push(['Category', 'Amount Spent'])
      for (let i = 0; i < this.categoryAnalytics.length; i++) {
        const element = this.categoryAnalytics[i];
        array.push([element.category, element.amount * 1])
      }
      this.donutChartData = array
    },
    mapLineChartData(previousMonthData) {
      let array = []
      array.push(['Day', 'Current Month', 'Previous Month'])

      let arrayLength = this.lineChartResponse.length > previousMonthData.length ? this.lineChartResponse.length : previousMonthData.length

      for (let i = 0; i < arrayLength; i++) {
        let dayNumber = this.lineChartResponse.length > previousMonthData.length ? this.lineChartResponse[i].day : previousMonthData[i].day
        let amountCurrentMonth = this.lineChartResponse[i] !== undefined ? this.lineChartResponse[i].amount : 0
        let amountPreviousMonth = previousMonthData[i] !== undefined ? previousMonthData[i].amount : 0
        array.push([dayNumber, amountCurrentMonth * 1, amountPreviousMonth * 1])
      }

      this.lineChartData = array

    },
    toggleChartPopUp() {
      this.showChartPopup = true
    },
    bankChangedCallback(val) {
      this.currentBank = val
      this.itemId = val.itemId
      this.accounts = this.currentBank.accounts
      this.currentAccount = this.accounts[0]
      this.accountId = this.currentAccount.accountId
    },
    accountChangedCallback(val) {
      this.accountId = val
    },
    transactionDateChangedCallback(val) {
      this.lineChartCompareDate = moment(val).subtract(1, 'month').format("YYYY-MM-DD")
      this.lineChartDate = moment(val).format("YYYY-MM-DD")
    },
    searchCallback() {
      // method param : {bank, accountId, category, transactionDate}
      this.loadAllDataExceptBank()
    }
  }
}
</script>
