<template>
    <div class="flex h-full w-full">
        <GChart class="mx-auto w-full" :settings="{ packages: ['corechart'], }" type="AreaChart" :data="lineChartData"
            :options="options" @ready="onChartReady" />
    </div>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';

export default {
    props: {
        lineChartData: {
            type: Array,
            required: true
        },
        monthName: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            chart: null,
            gvar: null, // this is placeholder for google variable
            options: {
                height: '100%', 
                width: '100%',
                legend: { position: 'none' },
                hAxis: {
                    title: 'Day number',
                    titleTextStyle: {
                        color: '#333'
                    }
                },
                vAxis: {
                    minValue: 0,
                    title: 'Amount',
                    titleTextStyle: {
                        color: '#333'
                    },
                },
                colors: ['#f44336', '#2196f3'],
                animation: {
                    startup: true,
                    easing: 'linear',
                    duration: 200,
                },
                
            }
        }
    },

    beforeDestroy() {
        if (this.chart) {
            this.chart.clearChart()
        }
    },

    components: {
        GChart
    },

    methods: {
        onChartReady(chart, google) {
            this.gvar = google
            this.chart = chart
        }
    }
}
</script>

<style></style>